import { useAccessToken } from '~/composables/auth'

export default defineNuxtRouteMiddleware(() => {
  if (process.server) {
    return
  }

  const accessToken = useAccessToken()

  if (accessToken.value) {
    return navigateTo('/')
  }
})
